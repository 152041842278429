<template>
  <div class="ping-status">
    <div v-if="pingTime !== null">
      <small>
        ping: {{ pingTime }} ms <code>google.com</code>
        <span v-if="role == 'host'">
          | ping: {{ pingTimeSal }} ms <code>salpalaran.com</code></span
        >
      </small>
    </div>
    <div v-else>
      <code
        ><small
          >You are offline, (
          {{
            countdown == 1
              ? 'Starting'
              : countdown == 7
              ? 'Failed'
              : countdown + 's'
          }}
          ) Checking internet connection...</small
        ></code
      >
    </div>
  </div>
</template>

<script>
import { getUserData } from '@/auth/utils'
export default {
  data() {
    return {
      pingTime: null,
      pingTimeSal: null,
      countdown: 7,
      role: getUserData().role
    }
  },
  methods: {
    async checkPing() {
      const start = Date.now()
      try {
        // Replace this with an actual URL that responds to ping requests
        await fetch('google.com', { method: 'HEAD' })
        const end = Date.now()
        this.pingTime = end - start
      } catch (error) {
        this.pingTime = null
      }
      if (this.role == 'host') {
        const startSal = Date.now()
        try {
          // Replace this with an actual URL that responds to ping requests
          await fetch('salpalaran.com', { method: 'HEAD' })
          const endSal = Date.now()
          this.pingTimeSal = endSal - startSal
        } catch (error) {
          this.pingTimeSal = null
        }
      }
    },
    updateCountdown() {
      this.countdown -= 1
      if (this.countdown === 0) {
        this.checkPing()
        this.countdown = 7
      }
    }
  },
  mounted() {
    this.checkPing()
    setInterval(this.updateCountdown, 1000) // Update countdown every 1 second
  }
}
</script>

<style scoped>
.ping-status {
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 2px;
  background-color: #f0f0f037;
  border: 1px solid #0097ee;
  cursor: pointer;
  transition: background-color 0.3s;
}
.ping-status:hover {
  border: 4px solid #0000004d;
  animation: shake 5s ease-in-out infinite;
}
@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-5px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateX(5px);
  }
}
</style>
