<!-- TODO #4 NAV BAR -->
<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-lg-flex">
      <!-- TEMA REPAIR ZOOM IN ZOOM OUT -->
      <bookmarks />
      <b-alert
        :show="dismissCountDown"
        dismissible
        class="mb-0"
        variant="warning"
        @dismissed="dismissCountDown = 0"
        @dismiss-count-down="countDownChanged"
      >
      </b-alert>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <!-- <search-bar /> -->
      <!-- <cart-dropdown /> -->

      <!-- <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="mt-75"
        @click="dismissCountDown = dismissSecs"
      >
        Show alert with count-down timer
      </b-button> -->
      <!-- <ping></ping> -->
      <locale class="d-none" />
      <notification-dropdown />
      <!-- <notification-dropdown /> -->
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import { BLink, BNavbarNav } from 'bootstrap-vue'
import Bookmarks from './components/Bookmarks.vue'

import Locale from './components/Locale.vue'
import SearchBar from './components/SearchBar.vue'
import CartDropdown from './components/CartDropdown.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'
import useJwt from '@/auth/jwt/useJwt'
import DarkToggler from './components/DarkToggler.vue'
import Ping from './Ping.vue'

export default {
  components: {
    DarkToggler,
    BLink,
    Locale,
    // Navbar Components
    BNavbarNav,
    Bookmarks,
    SearchBar,
    CartDropdown,
    NotificationDropdown,
    UserDropdown,
    Ping
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      dismissSecs: 3,
      dismissCountDown: 0
    }
  },
  watch: {
    dismissCountDown() {
      // console.log('countdown to hot reload:', this.dismissCountDown)
      // $router.go(-1)
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      useJwt.http
        .get('app_update')
        .then((response) => {
          const { update_status, web_msg } = response.data.app_updates
          if (update_status === this.$forceToRefresh) {
            this.$swal({
              title: '',
              html: web_msg,
              // eslint-disable-next-line global-require
              imageUrl: require('@/assets/images/pages/not-authorized.svg'),
              // imageWidth: 402,
              imageHeight: 350,
              // position: 'top',
              // backdrop: false,
              // imageWidth: 100,
              // imageHeight: 100,

              imageAlt: 'system_update',
              confirmButtonText: 'CLOSE',
              showCancelButton: false,
              customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-outline-danger ml-1'
              },

              buttonsStyling: false
            }).then(async () => {
              useJwt.http.put('app_update/next/logout').then(() => {
                this.$router.go()
              })
            })
          } else if (update_status === this.$forceToLogout) {
            console.log('user automaticaly logout:', update_status)

            localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
            localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
            localStorage.removeItem('userData')

            // Redirect to login page
            // this.$router.go()
            this.$router.push({ name: 'auth-login' })
          }
        })
        .catch((err) => {
          //
        })
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    }
  }
}
</script>
