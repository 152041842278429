var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ping-status"
  }, [_vm.pingTime !== null ? _c('div', [_c('small', [_vm._v(" ping: " + _vm._s(_vm.pingTime) + " ms "), _c('code', [_vm._v("google.com")]), _vm.role == 'host' ? _c('span', [_vm._v(" | ping: " + _vm._s(_vm.pingTimeSal) + " ms "), _c('code', [_vm._v("salpalaran.com")])]) : _vm._e()])]) : _c('div', [_c('code', [_c('small', [_vm._v("You are offline, ( " + _vm._s(_vm.countdown == 1 ? 'Starting' : _vm.countdown == 7 ? 'Failed' : _vm.countdown + 's') + " ) Checking internet connection...")])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }