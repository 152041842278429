var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "navbar-container d-flex content align-items-center"
  }, [_c('ul', {
    staticClass: "nav navbar-nav d-xl-none"
  }, [_c('li', {
    staticClass: "nav-item"
  }, [_c('b-link', {
    staticClass: "nav-link",
    on: {
      "click": _vm.toggleVerticalMenuActive
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "MenuIcon",
      "size": "21"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "bookmark-wrapper align-items-center flex-grow-1 d-lg-flex"
  }, [_c('bookmarks'), _c('b-alert', {
    staticClass: "mb-0",
    attrs: {
      "show": _vm.dismissCountDown,
      "dismissible": "",
      "variant": "warning"
    },
    on: {
      "dismissed": function dismissed($event) {
        _vm.dismissCountDown = 0;
      },
      "dismiss-count-down": _vm.countDownChanged
    }
  })], 1), _c('b-navbar-nav', {
    staticClass: "nav align-items-center ml-auto"
  }, [_c('locale', {
    staticClass: "d-none"
  }), _c('notification-dropdown'), _c('user-dropdown')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }